/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
    function owl_otstr(){
        $('.owl-carousel').slideDown(500);
    }
  // Load Events
  $(document).ready(UTIL.loadEvents);
    
    setTimeout(owl_otstr, 1000);
    
    var w = $(window).width(),
        m = $('.main-menu > li.menu-item-has-children'); // Получаем ширину окна
    if (w > 682) { 
m.hover(
    function() {
        $(this).children('.sub-menu').stop(true, true).slideDown(400);
        $(this).children('a').click(function(el){
            if(!$(this).hasClass('open') && w <=1024){
                $(this).addClass('open');
                el.preventDefault();
            }
        });
        
    },
    function() {
        $(this).children('.sub-menu').slideUp(400);    
    }
);}
    if(w <= 768){
        $('.sidebar').insertAfter('.main');
    }
    
    $(window).resize(function (){
    var w = $(window).width();
     if(w <= 768){
        $('.sidebar').insertAfter('.main');
    }else{
        $('.sidebar').insertBefore('.main');
    }   
    });
    $('.search-buttom').toggle(
        function() {
            $(this).next('.search-form').slideDown(500,'swing');
            $(this).addClass('close_search-form');
        },
        function() {
            $(this).next('.search-form').slideUp(300,'linear');
            $(this).removeClass('close_search-form');
        }
    );
    $('span.read-more').click(function(){
        $('.default-hidden').show();
        $(this).hide();
    });
    $('.button-menu').toggle(
        function() {
            $('.main-menu').slideDown(700,'swing');
            $(this).addClass('close_main-menu');
        },
        function() {
            $('.main-menu').slideUp(500,'linear');
            $(this).removeClass('close_main-menu');
        }

    );
    if (w <= 783) { //694
        $('.header .head-phone').insertBefore($('.logo'));
        $('.box-main-menu').insertBefore($('.logo'));
        //$('.head-social').insertBefore($('.button-menu'));
        $('.copy').prepend( $('#footer .footer-text-2') );
        $('.copy').prepend( $('#footer .footer-text-1') );

    }
    else if (w > 783){
        $('.box-main-menu').insertAfter($('.header'));
        //$('.head-social').insertBefore($('.link-site'));
        $('.header .head-phone').insertBefore($('.head-social'));
        $('#footer .footer-text-1').insertAfter($('#footer .footer-map.alignleft .map'));
        $('#footer .footer-text-2').insertAfter($('#footer .footer-map.alignright .map'));
    }

    $(window).resize(function () {
        var w = $(window).width();
        if (w <= 783) { //694
            $('.header .head-phone').insertBefore($('.logo'));
            $('.box-main-menu').insertBefore($('.logo'));
            //$('.head-social').insertBefore($('.button-menu'));


            $('.copy').prepend( $('#footer .footer-text-2') );
            $('.copy').prepend( $('#footer .footer-text-1') );
        }
        else if (w > 783){
            $('.box-main-menu').insertAfter($('.header'));
            //$('.head-social').insertBefore($('.link-site'));
            $('.header .head-phone').insertBefore($('.head-social'));
            $('#footer .footer-text-1').insertAfter($('#footer .footer-map.alignleft .map'));
            $('#footer .footer-text-2').insertAfter($('#footer .footer-map.alignright .map'));
        }
    });

    if (w <= 783) { //784
        $('.box-form-search').insertAfter($('.head-social'));
    }
    else if (w > 783){
        $('.box-form-search').insertAfter($('.main-menu'));
    }

    $(window).resize(function () {
        var w = $(window).width();
        if (w <= 783) { //
            $('.box-form-search').insertAfter($('.head-social'));
        }
        else if (w > 783){
            $('.box-form-search').insertAfter($('.main-menu'));
        }
    });
    if (w < 544) { //404
        $('#footer .footer-box-map > .alignright .title-map').insertAfter($('.footer-info .head-phone'));
        $('#footer .footer-box-map > .alignleft .title-map').insertAfter($('.footer-info .head-phone'));
        $('#footer .copy').insertAfter($('#footer .footer-box-map'));
    }
    else if (w > 544){
        // $('#footer .copy').insertAfter($('.footer-main-menu'));
    }

    $(window).resize(function () {
        var w = $(window).width();
        if (w < 544) { //
            //$('#footer .footer-box-map > .alignright .title-map').insertAfter($('#footer .footer-box-map > .alignleft .title-map'));
            $('#footer .footer-box-map > .alignright .title-map').insertAfter($('.footer-info .head-phone'));
            $('#footer .footer-box-map > .alignleft .title-map').insertAfter($('.footer-info .head-phone'));
            //$('#footer .copy').insertAfter($('#footer .footer-box-map'));
        }
        else if (w > 544){
            //$('#footer .copy').insertAfter($('.footer-main-menu'));
            $('.footer-info .title-map-2').insertBefore($('#footer .footer-box-map > .alignright #map-2'));
            $('.footer-info .title-map-1').insertBefore($('#footer .footer-box-map > .alignleft #map'));

            //$('#footer .footer-box-map > .alignleft').prepend('title-map-1');
            //$('#footer .footer-box-map > .alignright').prepend('title-map-2');
        }
    });
})(jQuery); // Fully reference jQuery after this point.
